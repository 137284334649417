<!--全自动推片染色机  -->
<template>
  <div id="bloodPushing">
    <Header></Header>
    <div class="bloodPushing-img">
       <Breadcrumb
        :productType="productType"
        :productName="productName"
      ></Breadcrumb>
      <img src="@/assets/image/bloodPushing/pushing.png" alt="" />
      <div class="illustrate-box">
        <div class="title wow animate__animated animate__fadeInUp">
          全自动推片染色机
        </div>
        <div class="series wow animate__animated animate__fadeInUp">
          CR-SMS120
        </div>
        <div class="line"></div>
        <ul class="keywords wow animate__animated animate__fadeInUp">
          <li>便捷</li>
          <li>高效</li>
          <li>优质</li>
          <li>价优</li>
        </ul>
      </div>
    </div>
    <div class="bloodPushing-description">
      <div class="prod-single">
        <div
          class="parameter-box feature-one wow animate__animated animate__fadeInUp"
        >
          <ul>
            <li>
              <div class="theme">工作模式</div>
              <div class="describe">
                支持推片染色、单推片、单染色、打印玻片4种工作模式任意选择
              </div>
            </li>
            <li>
              <div class="theme">上机检测</div>
              <div class="describe">
                原始管直接上机，闭盖穿刺，一键式操作，无人值守
              </div>
            </li>
            <li>
              <div class="theme">流程可视化</div>
              <div class="describe">
                操作界面动态显示每一个样本处理流程，进座一目了然
              </div>
            </li>
            <li>
              <div class="theme">样本可溯源</div>
              <div class="describe">
                自动根据样本条码打印玻片条码，方便样本溯源。
              </div>
            </li>
          </ul>
        </div>
        <div class="prod-img">
          <img
            class="wow animate__animated animate__fadeInUp"
            src="@/assets/image/bloodPushing/des-1.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="prod-single">
        <div
          class="parameter-box feature-two wow animate__animated animate__fadeInUp"
        >
          <div class="title">高效</div>
          <p class="detail">推片: 每小时最快处理速度为120个样本</p>
          <p class="detail">梁色: 每小时最快处理速度为60个样本</p>
          <p class="detail">推片+染色: 每小时最快处理速度为80个样本</p>
        </div>
        <div class="prod-img">
          <img
            class="wow animate__animated animate__fadeInUp"
            src="@/assets/image/bloodPushing/des-2.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="prod-single">
        <div
          class="parameter-box feature-three wow animate__animated animate__fadeInUp"
        >
          <div class="title">优质</div>
          <p class="detail">
            推片:自动断血液黏度，提供15种推片级别。仿生机械手推片推片更合理。推片结果更可靠。
          </p>
          <p class="detail">
            染色:标准化染色流程。去除人为因素影响，保证染色效果的稳定可靠
          </p>
        </div>
        <div class="prod-img">
          <img
            class="wow animate__animated animate__fadeInUp"
            src="@/assets/image/bloodPushing/des-3.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="prod-single">
        <div
          class="parameter-box parameter-text wow animate__animated animate__fadeInUp"
        >
          <div class="title">价优</div>
          <div class="detail">
            设备、试剂、耗材由公司自主研发、自主生产，且支持第三方染液上机。成本更可控
          </div>
        </div>
        <div class="prod-img">
          <img
            class="wow animate__animated animate__fadeInUp"
            src="@/assets/image/bloodPushing/des-4.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
    <RelatedProduct :swiperData="relatProd"></RelatedProduct>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import api from "../../../../api/api";
import Header from "../../../components/Header.vue";
import Footer from "../../../components/Footer.vue";
import Breadcrumb from "../../../components/Breadcrumb.vue";
import RelatedProduct from "../componets/RelatedProduct.vue";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
    Breadcrumb,
    RelatedProduct,
  },
  data() {
    return {
      productType: "产品",
      productName: "全自动推片染色机",
      relatProd: [
        {
          title: "全自动特殊染色机",
          src: require("@/assets/image/product/film-4.png"),
          path: "/product/special",
        },
        {
          title: "DNA定量分析诊断试剂",
          src: require("@/assets/image/product/reagent-2.png"),
          path: "/product/dnaReagent",
        },
        {
          title: "DNA倍体定量分析系统",
          src: require("@/assets/image/product/diagnosis-1.png"),
          path: "/product/dnaProd",
        },
        {
          title: "扫描通量600片",
          src: require("@/assets/image/product/digit-1.png"),
          path: "/product/gemini",
        },
        {
          title: "扫描通量120片",
          src: require("@/assets/image/product/digit-2.png"),
          path: "/product/gemini",
        },
        {
          title: "扫描通量30片",
          src: require("@/assets/image/product/digit-3.png"),
          path: "/product/gemini",
        },
        {
          title: "扫描通量2片",
          src: require("@/assets/image/product/digit-4.png"),
          path: "/product/gemini",
        },
      ],
      urlPath: "", // 当前路由
      allProdList: [], // 所有产品数据
      currentList: {}, // 当前页面数据
    };
  },
  async created() {
    await this.getPordList(1, 100, {});
    await this.getChooseId(this.currentList.id);
  },
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {
    $route: {
      // $route可以用引号，也可以不用引号  监听的对象
      handler(to, from) {
        // console.log(to.path);
        this.urlPath = to.path;
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
    // 获取所有产品信息
    async getPordList(pageIndex, pageSize, params) {
      try {
        var res = await api.getPorductList(pageIndex, pageSize, params);
        if (res && res.data && res.data.code === 200) {
          this.allProdList = res.data.models;
          let presentProd = this.allProdList.filter((item) => {
            return item.summary == this.urlPath;
          });
          this.currentList = presentProd.length != 0 ? presentProd[0] : 0;
        } else {
        }
      } catch (error) {}
    },
    // 获取相关产品信息
    async getChooseId(prodId) {
      try {
        var res = await api.getProdRelationByid(prodId);
        if (res && res.data && res.data.code === 200) {
          if (res.data.models) {
            let result = res.data.models[0].relationID;
            if (result) {
              let relationArr = result.split(",");
              let tepmArr = [];
              relationArr.forEach((item) => {
                tepmArr.push(Number(item));
              });

              let relatedList = [];
              tepmArr.forEach((items) => {
                let secData = this.allProdList.filter((item) => {
                  return item.id == items;
                });
                if (secData.length != 0) {
                  relatedList.push(secData[0]);
                }
              });

              this.relatProd = relatedList;

              console.log(this.relatProd);
            } else {
            }
          }
        } else {
        }
      } catch (error) {}
    },
  },
};
</script>
    
<style lang="scss">
#bloodPushing {
  padding-top: 6.5rem;
  .bloodPushing-img {
    img {
      display: block;
      width: 96%;
      margin: 0 auto;
    }
    .illustrate-box {
      padding: 1.25rem;
      .title {
        font-size: 1.5rem;
        font-family: "SourceHanSansSC-Medium";
        margin-bottom: 1rem;
      }
      .series {
        font-size: 1.5rem;
        font-family: "SourceHanSansSC-Medium";
        margin-bottom: 1.5rem;
      }
      .line {
        width: 4.25rem;
        height: 3px;
        margin-bottom: 1.25rem;
        background: #177a73;
      }
      .keywords {
        display: flex;
        li {
          width: 20%;
          height: 2rem;
          line-height: 2rem;
          margin-right: 4%;
          color: #fff;
          background: #177a73;
          border-radius: 2rem;
          text-align: center;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }
  .bloodPushing-description {
    padding: 1.25rem;
    width: 100%;
    box-sizing: border-box;
    // background-image: url("../../../../assets/image/product-detail/des-bg.png");
    background-size: cover;
    .prod-single {
      margin-bottom: 2.8rem;
      .parameter-box {
        margin-bottom: 1rem;
        ul {
          li {
            margin-bottom: 1rem;
            .theme {
              font-size: 1.2rem;
              font-family: "SourceHanSansSC-Medium";
              margin-bottom: 0.5rem;
            }
            .describe {
              font-size: 1rem;
              font-family: "OPlusSans3-Regular";
            }
          }
        }
        .title {
          font-size: 1.2rem;
          font-family: "SourceHanSansSC-Medium";
          margin-bottom: 0.8rem;
        }
        .detail {
          font-size: 1rem;
          font-family: "OPlusSans3-Regular";
          margin-bottom: 0.8rem;
        }
      }
      .prod-img {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    .characteristic {
      margin: auto;
      .feature-box {
        .feature-item {
          display: flex;
          align-items: flex-start;
          width: 100%;
          margin-bottom: 3rem;
          .left {
            width: 15%;
            margin-right: 5%;
            img {
              padding-top: 0.4rem;
              width: 100%;
            }
          }
          .right {
            width: 80%;
            .title {
              font-size: 1.2rem;
              font-family: "SourceHanSansSC-Medium";
              margin-bottom: 1rem;
            }
            .desc {
              font-size: 1rem;
              font-family: "OPlusSans3-Regular";
            }
          }
        }
      }
    }
    .pelleter-icons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        width: 28%;
        margin-right: 20%;
        margin-bottom: 1.5rem;
        &:nth-of-type(2n) {
          margin-right: 0;
        }
        img {
          width: 100%;
          margin-bottom: 0.8rem;
        }
        .text {
          font-size: 0.875rem;
          text-align: center;
          font-family: "SourceHanSansSC-Medium";
        }
      }
    }
  }
  .footer {
    background: #f5f5f5;
  }
}
</style>